import React, { useEffect, useState } from "react";
import { deletePost } from "../api";

export default function Card({ post }) {
  const [confirm, setConfirm] = useState(false);
  const [currentScene, setCurrentScene] = useState(0);
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentScene === post.scenes.length) {
        setCurrentScene(0);
      } else {
        // if (post.scenes[currentScene - 1].length !== 0) {
        setCurrentScene(currentScene + 1);
        // }
      }
    }, 800);

    return () => clearInterval(intervalId);
  }, [currentScene, post.scenes.length]);

  let deleteFn = () => {
    if (deleted) {
      return;
    }
    setConfirm(!confirm);
    if (confirm) {
      (async () => {
        let res = await deletePost(post._id);
        if (res.status === 200) {
          setDeleted(true);
        }
      })();
    }
    setTimeout(() => {
      setConfirm(false);
    }, 2000);
  };

  return (
    <div className={`${deleted ? "opacity-20" : ""} md:w-1/3 md:p-3 `}>
      <div className="max-w overflow-hidden bg-dark shadow-lg relative">
        <a href={`/${post._id}`} target="_blank" rel="noreferrer">
          {post.type === "HQ" ? (
            <img
              className="object-cover w-full md:rounded overflow-hidden min-h-[227px]"
              src={
                currentScene === 0
                  ? post.thumbnail
                  : post.scenes[currentScene - 1]
              }
              alt="..."
            ></img>
          ) : (
            <video
              className="object-cover w-full md:rounded overflow-hidden min-h-[227px]"
              autoPlay
              muted
              type="video/webM"
              preload="auto"
              loop
              src={post.thumbnail}
              alt="..."
            ></video>
          )}
        </a>

        <div className="flex items-center justify-between p-2 w-full bg-gradient-to-t from-[#00000010] absolute bottom-0">
          <h1 className="text-sm text-white shadow-sm">{post.title}</h1>
          <button
            className="flex items-center p-2 font-medium tracking-wide bg-black text-white capitalize transition-colors focus:outline-none focus:shadow-none duration-300 bg-opacity-40 transform rounded-lg hover:bg-gray-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
            onClick={deleteFn}
          >
            {!confirm ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                <polyline points="3 6 5 6 21 6" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="20 6 9 17 4 12" />
              </svg>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
