import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "./Card";
import Pagination from "./Pagination";
import Navbar from "./Navbar";
import { getTags } from "../api";

export default function Tag() {
  let { tag } = useParams();

  const [data, setData] = useState();
  const [page, setPage] = useState(1);

  useEffect(() => {
    (async () => {
      setData(await getTags(tag, page));
    })();
  }, [page]);

  return !data ? (
    ""
  ) : (
    <>
      <Navbar setData={setData} data={data} />
      <div className="flex flex-col bg-wall md:flex-row flex-wrap justify-center items-center">
        {data.posts.map((x, i) => (
          <Card key={i} post={x} />
        ))}
        <Pagination
          current={page}
          maxPage={data.maxPage}
          total={data.total}
          setPage={setPage}
        />
      </div>
    </>
  );
}
