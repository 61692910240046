import React, { useEffect, useState } from "react";
import Card from "./Card";
import { getShuffle } from "../api";
import Navbar from "./Navbar";

export default function Shuffle() {
  const [data, setData] = useState();
  // const data2 = async () =>
  // const data = data2;

  useEffect(() => {
    (async () => {
      setData(await getShuffle());
    })();
  }, []);

  return !data ? (
    ""
  ) : (
    <>
      <Navbar setData={setData} data={data} />
      <div className="flex flex-col bg-wall md:flex-row flex-wrap justify-center items-center">
        {data.posts.map((x, i) => (
          <Card key={i} post={x} />
        ))}
      </div>
    </>
  );
}
