import React from "react";
import { addPost } from "../api";

export default function Navbar({ data, setData, current = 0 }) {
  // const [showSearch, setShowSearch] = useState(false);
  // const [search, setSearch] = useState("");
  // const [suggestion, setSuggestion] = useState([
  //   "example 1",
  //   "example 2",
  //   "example 3",
  // ]);

  const addPostHelper = () => {
    navigator.clipboard.readText().then((clipText) => {
      if (clipText.includes("hqporner") || clipText.includes("trendyporn")) {
        (async () => {
          let post = await addPost(clipText);
          if (post) {
            if (data) {
              setData(data.unshift(post));
            }
          }
        })();
      }
    });
  };

  const getTabBgColor = (n) => {
    return current === n
      ? " !bg-wall-alt !text-primary"
      : " ";
  };

  return (
    <>
      <nav className="shadow bg-wall">
        <div className=" text-primary container md:w-fit md:gap-2 md:mx-auto flex items-center justify-between px-4 py-2  capitalize">
          <a
            href="/shuffle"
            className={`bg-primary transition-colors text-wall duration-300 md:px-8 transform p-4 rounded-lg ${getTabBgColor(
              0
            )}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="16 3 21 3 21 8" />
              <line x1="4" y1="20" x2="21" y2="3" />
              <polyline points="21 16 21 21 16 21" />
              <line x1="15" y1="15" x2="21" y2="21" />
              <line x1="4" y1="4" x2="9" y2="9" />
            </svg>
          </a>
          <a
            href="/"
            className={`bg-primary transition-colors text-wall duration-300 md:px-8 transform p-4 rounded-lg ${getTabBgColor(
              1
            )}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
              <polyline points="9 22 9 12 15 12 15 22" />
            </svg>
          </a>
          <button
            href="."
            className={`bg-primary transition-colors text-wall duration-300 md:px-8 transform p-4 rounded-lg ${getTabBgColor(
              2
            )}`}
            onClick={addPostHelper}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="12" y1="5" x2="12" y2="19" />
              <line x1="5" y1="12" x2="19" y2="12" />
            </svg>
          </button>
          <a
            href="/popular"
            className={`bg-primary transition-colors text-wall duration-300 md:px-8 transform p-4 rounded-lg ${getTabBgColor(
              3
            )}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-bar-chart"
            >
              <line y1="18" x2="5" y2="18" x1="0"></line>
              <line y1="12" x2="10" y2="12" x1="0"></line>
              <line y1="6" x2="20" y2="6" x1="0"></line>
            </svg>
          </a>
          {/* <button
            onClick={() => setShowSearch(!showSearch)}
            className={`text-gray-800 transition-colors duration-300 md:px-8 transform p-4 dark:text-gray-200 rounded-lg ${getTabBgColor}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="11" cy="11" r="8" />
              <line x1="21" y1="21" x2="16.65" y2="16.65" />
            </svg>
          </button> */}
          <a
            href="https://m.hqporner.com"
            target="_blank"
            rel="noreferrer"
            className={`bg-primary transition-colors text-wall duration-300 md:px-8 transform  p-3 text-[16px] rounded-lg ${getTabBgColor(
              4
            )}`}
          >
            HQ
          </a>
          <a
            href="https://www.trendyporn.com"
            target="_blank"
            rel="noreferrer"
            className={`bg-primary transition-colors text-wall duration-300 md:px-8 transform  p-3 text-[16px] rounded-lg ${getTabBgColor(
              5
            )}`}
          >
            TP
          </a>
        </div>
      </nav>
      {/* {showSearch ? (
        <div
          className="fixed inset-0 z-10 translate-y-[-40%] w-full"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:p-0">
            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl dark:bg-gray-900 sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div className="mt-4 flex flex-col gap-2">
                <input
                  type="text"
                  value={search}
                  autoFocus
                  onChange={(e) => setSearch(e.value)}
                  className="flex-1 block h-10 px-4 py-2 mx-1 text-sm text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />

                {suggestion.map((x, i) => (
                  <a
                    href="."
                    key={i}
                    className="w-full px-4 py-2 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border border-gray-200 rounded-md dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800 hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40"
                  >
                    {x}
                  </a>
                ))}

                <button
                  className="w-full px-4 py-2 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border border-gray-200 rounded-md dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800 hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40"
                  onClick={() => setShowSearch(!showSearch)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )} */}
    </>
  );
}
