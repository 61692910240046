import "./App.css";
import Show from "./components/Show";
import Home from "./components/Home";
import Popular from "./components/Popular";
import Shuffle from "./components/Shuffle";
import Tag from "./components/Tag";
import { Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";

function App() {
  const [orientation, setOrientation] = useState();
  const detect = () =>
    setOrientation(
      !navigator.maxTouchPoints
        ? "desktop"
        : !window.screen.orientation.angle
        ? "portrait"
        : "landscape"
    );
  useEffect(() => {
    window.addEventListener("resize", detect());
    window.addEventListener("orientationchange", detect());
  });

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/popular" element={<Popular />} />
        <Route path="/shuffle" element={<Shuffle />} />
        <Route path="/:id" element={<Show orientation={orientation} />} />
        <Route path="/tag/:tag" element={<Tag />} />
      </Routes>
    </>
  );
}

export default App;
