import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPost, deletePost, imageUpdate, refreshPost } from "../api";
import Navbar from "./Navbar";

export default function Show() {
  let { id } = useParams();
  const [post, setPost] = useState();
  const [dateDiff, setDateDiff] = useState();
  const [confirm, setConfirm] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [orientation, setOrientation] = useState();

  const detect = () => {
    setOrientation(
      !navigator.maxTouchPoints
        ? "desktop"
        : !window.screen.orientation.angle
        ? "portrait"
        : "landscape"
    );
  };
  useEffect(() => {
    window.addEventListener("resize", detect());
    window.addEventListener("orientationchange", detect());
  });

  const getDateDiff = ({ h, d, w, m }) => {
    if (m !== 0) return [m, "months"];
    if (w !== 0) return [w, "weeks"];
    if (d !== 0) return [d, "days"];
    if (h !== 0) return [h, "hours"];
    else return ["few", "moments"];
  };

  useEffect(() => {
    (async () => {
      let post = await getPost(id);
      setPost(post);
      const postDate = new Date(post.timestamp);
      const currDate = new Date();
      const diff = Math.abs(currDate - postDate);
      let [h, d, w, m] = [
        Math.floor(diff / 3600000),
        Math.floor(diff / 86400000),
        Math.floor(diff / 604800000),
        Math.floor(diff / 2629746000),
      ];
      h -= d * 24;
      d -= w * 7;
      w -= m * 4;
      setDateDiff(
        getDateDiff({
          h: Math.max(h, 0),
          d: Math.max(d, 0),
          w: Math.max(w, 0),
          m: Math.max(m, 0),
        })
      );
    })();
  }, [id]);

  const addImageHelper = () => {
    navigator.clipboard.readText().then((clipText) => {
      if (clipText.includes("fastporn")) {
        (async () => {
          await imageUpdate(post._id, clipText);
        })();
      }
    });
  };

  const refreshHelper = () => {
    if (post.type === "HQ") {
      (async () => {
        await refreshPost(post._id).then((x) => {
          setPost({ ...post, link: x.link, timestamp: x.timestamp });
        });
      })();
    }
  };

  let deleteFn = () => {
    if (deleted) {
      return;
    }
    setConfirm(!confirm);
    if (confirm) {
      (async () => {
        const res = await deletePost(post._id);
        if (res.status === 200) {
          setDeleted(true);
        }
      })();
    }
    setTimeout(() => {
      setConfirm(false);
    }, 2000);
  };

  return !post ? (
    ""
  ) : (
    <>
      <Navbar current={-1} />
      <div className={`overflow-hidden bg-wall h-screen shadow-md`}>
        <div className="flex flex-col md:flex-row">
          <iframe
            className={
              orientation === "landscape"
                ? "z-50 overflow-hidden bg-black h-screen w-screen absolute top-0 left-0"
                : "w-screen md:w-10/12 m-auto aspect-video"
            }
            src={post.link}
            title={post.title}
            frameBorder="0"
            allowFullScreen={true}
            referrerPolicy="no-referrer"
          ></iframe>

          <div className="md:w-2/12">
            <div className="p-6 ">
              <span className="text-xs font-medium text-secondary uppercase">
                {`${dateDiff[0]} ${dateDiff[1]} ago`}
              </span>
              <div className="block mt-2 text-2xl font-semibold transition-colors duration-300 transform text-primary">
                {post.title}
              </div>

              <div className="mt-4">
                <div className="flex items-center gap-3 flex-wrap">
                  {post.tags.map((x, i) => (
                    <a
                      href={`/tag/${x}`}
                      className="text-wall font-semibold bg-secondary text-sm py-1 px-3 rounded-lg opacity-80"
                      tabIndex="0"
                    >
                      {x}
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className=" overflow-hidden md:bg-gray-800 fixed md:relative w-full md:w-11/12 md:mx-auto bottom-0">
              <div className="flex flex-row md:flex-col md:justify-items-stretch justify-between align-middle  p-2 gap-2">
                <a
                  href={post.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary bg-wall-alt bg-opacity-30 flex place-items-center md:w-full justify-center w-1/4 align-items-center py-3 transition-colors duration-300 transform rounded-md focus:outline-none focus:bg-wall focus:ring focus:ring-wall focus:ring-opacity-40"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                    <circle cx="12" cy="12" r="3" />
                  </svg>
                </a>

                <button
                  onClick={addImageHelper}
                  className="text-primary bg-wall-alt bg-opacity-30 flex place-items-center md:w-full justify-center w-1/4 align-items-center py-3 transition-colors duration-300 transform rounded-md focus:outline-none focus:bg-wall focus:ring focus:ring-wall focus:ring-opacity-40"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                  </svg>
                </button>

                <button
                  onClick={refreshHelper}
                  className="text-primary bg-wall-alt bg-opacity-30 flex place-items-center md:w-full justify-center w-1/4 align-items-center py-3 transition-colors duration-300 transform rounded-md focus:outline-none focus:bg-wall focus:ring focus:ring-wall focus:ring-opacity-40"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="23 4 23 10 17 10" />
                    <polyline points="1 20 1 14 7 14" />
                    <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15" />
                  </svg>
                </button>
                <button
                  onClick={deleteFn}
                  className="text-primary bg-wall-alt bg-opacity-30 flex place-items-center md:w-full justify-center w-1/4 align-items-center py-3 transition-colors duration-300 transform rounded-md focus:outline-none focus:bg-wall focus:ring focus:ring-wall focus:ring-opacity-40"
                >
                  {!confirm ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                      <polyline points="3 6 5 6 21 6" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
