import React from "react";

export default function Pagination({ current, maxPage, total, setPage }) {
  const page = current === 1 ? current + 1 : current;
  const pages = [page - 1, page, page + 1, maxPage - 1, maxPage];
  return (
    <div className="w-full text-primary bg-wall-alt">
      <div className="container flex flex-col items-center px-6 py-5 mx-auto space-y-6 sm:flex-row sm:justify-between sm:space-y-0 ">
        <div className="mx-2">
          {pages.map((x, i) => (
            <button
              key={i}
              href="."
              className={`${
                x === current ? "bg-wall" : ""
              } inline-flex items-center justify-center px-4 py-1 mx-2  transition-colors duration-300 transform  rounded-lg  hover:bg-gray-800`}
              onClick={() => setPage(x)}
            >
              {x}
            </button>
          ))}
        </div>

        <div className="text-gray-400">
          <span className="font-medium text-gray-700 dark:text-gray-100">
            1 - 30
          </span>{" "}
          of {total} records
        </div>
      </div>
    </div>
  );
}
