import axios from "axios";
const host =
  process.env.NODE_ENV === "development" ? "http://localhost:4000" : "";

export const getHome = async (page = 1) => {
  try {
    const response = await axios.get(`${host}/api/page=${page}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getPopular = async (page = 1) => {
  try {
    const response = await axios.get(`${host}/api/popular/page=${page}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getShuffle = async () => {
  try {
    const response = await axios.get(`${host}/api/shuffle`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getPost = async (id) => {
  try {
    const response = await axios.get(`${host}/api/${id}`);
    return response.data.post;
  } catch (error) {
    console.error(error);
  }
};

export const addPost = async (url) => {
  try {
    const response = await axios.post(`${host}/api/add`, { url });
    return response.data.post;
  } catch (error) {
    console.error(error);
  }
};

export const deletePost = async (id) => {
  try {
    const response = await axios.delete(`${host}/api/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const imageUpdate = async (id, image) => {
  try {
    const response = await axios.put(`${host}/api/${id}`, { image });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const refreshPost = async (id) => {
  try {
    const response = await axios.get(`${host}/api/refresh/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getTags = async (tag, page = 1) => {
  try {
    const response = await axios.get(`${host}/api/tag/${tag}/page=${page}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
